<template>
<div id="js-body-wrap" class="body-wrap">
    <!-- 'animate' class -->
    <header class="ui-header" >
    <div class="options-wrap">
        <div class="options-image-wrap">
            <img class="options-image" :src="require(`@/assets/images/${logoSrc}`)" alt="logo" />
        </div>
        <div class="op-wrap">
            <ui-button class="logout-btn logout-btn--bordered" @click="logout">
                {{ $t("log_out_btn") }}
            </ui-button>
            <lang-dropdown :i18nMode="$i18n.locale" :locales="locales" class="lang-dropdown" @localeChange="handleLocaleChangeEvent" ></lang-dropdown>
        </div>
        </div>
    </header>

    <main>
        <div class="inner-wrap">
            
        <div class="banner">
            <div class="banner__image-wrapper">
            </div>
            <div class="banner__text-wrapper">
                <div class="banner-text-area">
                    
                <div>
                    <p class="banner__text-alt" v-html="$t('welcome_text_heading_alt')"></p>
                    <h4 class="banner__text-heading" v-html="$t('welcome_text_heading')"></h4>
                </div>
                <div>
                    <p class="banner__text" v-html="$t('welcome_text')"></p>
                </div>
                <a class="btn login-btn __video" href="#js-video-wrapper" @click="smoothScroll($event)"> {{ $t("welcome_btn") }}</a>
                    
                </div>
            </div>
        </div>
        </div>
        <div class="vid-wrap">
            
        <div id="js-video-wrapper" class="video-wrapper">
            <slot></slot>
            <p class="extra-text" v-if="isExtraTextAvailable()" v-html="$t('welcome_text_alt')"></p>
        </div>
        </div>
    </main>

    <footer>
        <copyright-footer class="consent-copyright" :locale="$i18n.locale"></copyright-footer>
    </footer>
</div>
</template>

<script>
import jQuery from 'jquery'
let $ = jQuery
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import UiButton from "@/components/UiButton";
import auth from "@/plugins/auth";
import utils from "@/common/utils";
import CopyRightLabel from "@/components/CopyRightLabel.vue";
import {
    logoPath
} from "@/config";
import {
    getSupportedLocales
} from "@/plugins/i18n";

export default {
    components: {
        "lang-dropdown": LocaleSwitcher,
        "ui-button": UiButton,
        "copyright-footer": CopyRightLabel,
    },
    data() {
        return {
            locales: getSupportedLocales(),
            logoSrc: logoPath,
        };
    },
    mounted() {
        document.title = this.$route.meta.pageTitle;
        utils.configureOnScrollAnimation();
        utils.addWistiaScript().then(() => {
            // do anything
        });
    },
    methods: {

        logout() {
            auth.logout(this, "pass", "passRev", "/login");
            //location.reload();
        },
        isExtraTextAvailable() {
            if ((this.$t('welcome_text_alt')) === 'welcome_text_alt')
                return false;
            else return true;
        },
        handleLocaleChangeEvent(locale){
            utils.handleLocaleChangeEvent(this,locale);
        },
        smoothScroll(event) {

            // Make sure this.hash has a value before overriding default behavior
            if (event.target.hash !== "") {
                // Prevent default anchor click behavior
                event.preventDefault();

                // Store hash
                var hash = event.target.hash;
                var hashOffsetTop = $(hash).offset().top
                // console.log("offset before is: " + hashOffsetTop);
                hashOffsetTop = hashOffsetTop - 0;
                //  console.log("offset after is: " + hashOffsetTop);

                // Using jQuery's animate() method to add smooth page scroll
                // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                $('html, body').animate({
                    scrollTop: hashOffsetTop
                }, 800, function () {

                });
            } // End if

        }
    },
};
</script>

<style lang="scss">
@import "../assets/styles/consent.scss";
</style>
