// Animation
export const isPageAnimationEnabled = true;


// Login Page Style
export const isDefaultStyle = true; // true or false


//Images
export const logoPath = "lghor.png";


//Pendo Analytics
export const pendoKey = '055e0a8b-b58d-43b8-6bc3-991ad9ea3974';
export const sponsorName = "Longboat";
export const studyURL = "consent.longboat.com";
export const studyId = "LBDEMO";
export const AppType = "CN";
export const pendoAccountName = studyId + '_' + AppType;


// Authentication
export const password = "consent88"
export const passwordReview = "consent88"
export const cookieExpiryTimeInMins = 10


// i18n localisation configuration
export const defaultLocale = "en";
export const supportedLocales = {
    en: "English UK",
    /*
    es: "Español",
    bd: "Belgisch-Nederlands",
    bd: "Belgisch-Nederlands",
    ck: "傳統中文",
    cs: "Čeština",
    ct: "繁體中文",
    de: "Deutsch",
    el: "ελληνικα",
    fc: "Français canadien",
    fr: "Français",
    hu: "Magyar",
    it: "Italiano",
    ko: "한국어",
    lp: "Español latinoamericano",
    ms: "Melayu",
    nl: "Nederlands",
    pl: "Polski",
    pt: "Português",
    ro: "Română",
    ru: "Русский",
    sp: "Español-EE.UU",
    th: "ไทย",
    zh: "简体中文",
    */
}