import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Index from '@/views/Index.vue'
import Home from '@/views/Home.vue'
import Amend from '@/views/Amend.vue'
import NotFound from '@/views/NotFound.vue'
import auth from '@/plugins/auth'
import middlewarePipeline from '@/middleware/middlewarePipeline'


Vue.use(VueRouter)

const isLoginPageEnabled = process.env.VUE_APP_IS_LOGIN_ENABLED;
const routes = [{
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            pageTitle: 'Login'
                // requiresAuth: false
        }
    },

    {
        path: '/',
        component: Index,
        children: [{
                // Home will be rendered inside Index's <router-view>
                // when / is matched
                path: '',
                component: Home,
                meta: {
                    //requiresAuth: true
                    pageTitle: 'Consent',
                    middleware: [auth.guardPage]
                }
            },
            {
                path: 'amend',
                component: Amend,
                meta: {
                    pageTitle: 'Consent',
                    middleware: [auth.guardPage]
                        //requiresAuth: true
                }
            }
        ]
    },
    { path: '/:catchAll(.*)', component: NotFound }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


if (isLoginPageEnabled === "true") {
    router.beforeEach((to, from, next) => {
        if (!to.meta.middleware) {
            return next()
        }
        const middleware = to.meta.middleware

        const context = {
            to,
            // from,
            next,
            // store
        }
        return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1)
        })
    })
}

export default router