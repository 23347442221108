const handleScrollAnimation = () => {

    const scrollOffset = 50;

    const scrollElements = document.querySelectorAll(".js-scroll");


    scrollElements.forEach((el) => {
        if (elementInView(el, scrollOffset)) {
            displayScrollElement(el);
        } else {
            hideScrollElement(el);
        }
    })


    function elementInView(el, offset = 0) {
        const elementTop = el.getBoundingClientRect().top;

        return (
            elementTop <=
            (window.innerHeight || document.documentElement.clientHeight) - offset
        );
    }

    function displayScrollElement(el) {
        el.classList.add("scrolled");
    }

    function hideScrollElement(el) {
        el.classList.remove("scrolled");
    }


};

export default handleScrollAnimation;