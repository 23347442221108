import Vue from 'vue';
import { password, passwordReview, cookieExpiryTimeInMins } from "@/config";


var auth = {

    /**
     * Summary Logs user out.
     * Description logs a user out by removing passwords for login and review/amend sites
     * and then sends user to another page eg login page.
     * @param  {Object} context             You will usually pass 'this' as a value
     * @param  {string} passwordKey
     * @param  {string} reviewPasswordkey
     * @param  {string} pathAfterLogout
     */
    logout: function(context, passwordKey, reviewPasswordkey, pathAfterLogout) {
        context.$cookies.remove(passwordKey)
        context.$cookies.remove(reviewPasswordkey)
        context.$router.push({ path: pathAfterLogout });
    },


    login: function(context, inputPassword) {
        if (inputPassword === password) {
            this.finalizeLogin(context, 'pass', inputPassword, '/');
        } else if (inputPassword === passwordReview) {
            this.finalizeLogin(context, 'passRev', inputPassword, 'amend');
        } else {
            context.error = true;
        }
    },

    finalizeLogin: function(context, passwordKey, inputPassword, pathAfterLogin) {
        context.error = false;
        context.$cookies.set(passwordKey, inputPassword, cookieExpiryTimeInMins * 60);
        this.redirectAfterLogin(context, pathAfterLogin);
    },

    redirectAfterLogin: function(context, pathAfterLogin) {
        context.$router.replace(context.$sessionStorage.get('redirectPath') || pathAfterLogin);
        context.$sessionStorage.remove('redirectPath');
    },

    guardPage: function({ to, next }) {
        if (Vue.$cookies.get("pass") === password || Vue.$cookies.get("passRev") === passwordReview) {
            next()
        } else {
            Vue.$sessionStorage.set('redirectPath', to.path);
            next('/login')
        }
    },


    guardPage2: function(to, from, next) {
        if (to.meta.requiresAuth) {
            if (Vue.$cookies.get("pass") === password || Vue.$cookies.get("passRev") === passwordReview) {
                next()
            } else {
                Vue.$sessionStorage.set('redirectPath', to.path);
                next('/login')
            }
        } else next()
    }
}


export default auth;