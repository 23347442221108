
import {
    pendoKey,
    pendoAccountName,
    studyId as studyID,
    studyURL,
    AppType as appType
} from "@/config";


export default (function(apiKey, pendoAccountName, studyID, studyURL, appType) {
    (function(p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)(function(m) {
            o[m] = o[m] || function() {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.eu.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');

    window.pendo.initialize({
        visitor: {
          //  id: pendoVisitorID, // Required if user is logged in, default creates anonymous ID
            // email:        // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional
            region: Intl.DateTimeFormat().resolvedOptions().timeZone,

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
        },

        account: {
            id: studyID + '_' + studyURL + '_' + appType, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            name: pendoAccountName,
            app_type: appType
        },
        disableCookies: true,
        disableGuides: false,
        disablePersistence: true,
        excludeAllText: true,
    });
})(pendoKey, pendoAccountName, studyID, studyURL, appType);