
<template>
  <consent-layout :key="new Date().getHours() + new Date().getMinutes()  + new Date().getSeconds()" >
                <cn-video v-if="videos[0].imageSrc" style="margin-bottom: 48px"  :videoImage="videos[0].imageSrc" :video="$t('video_one')" :videoTitle="$t('video_caption_one')" :videoText="$t('video_caption_one_text')"  class=""></cn-video>
                <cn-video v-if="videos[1].imageSrc" style="margin-bottom: 48px"  :videoImage="videos[1].imageSrc" :video="$t('video_two')" :videoTitle="$t('video_caption_two')" :videoText="$t('video_caption_two_text')" class="js-scroll"></cn-video>
                <cn-video v-if="videos[2].imageSrc" style="margin-bottom: 48px"  :videoImage="videos[2].imageSrc"  :video="$t('video_three')" :videoTitle="$t('video_caption_three')" :videoText="$t('video_caption_three_text')" class="js-scroll"></cn-video>
                <cn-video v-if="videos[3].imageSrc" style="margin-bottom: 48px"  :videoImage="videos[3].imageSrc" :video="$t('video_four')" :videoTitle="$t('video_caption_four')" :videoText="$t('video_caption_four_text')" class="js-scroll"></cn-video>
                <cn-video v-if="videos[4].imageSrc" style="margin-bottom: 48px"  :videoImage="videos[4].imageSrc" :video="$t('video_five')" :videoTitle="$t('video_caption_five')" :videoText="$t('video_caption_five_text')" class="js-scroll"></cn-video>
  </consent-layout>
</template>



<script>
import mixin from '@/common/mixin';

export default {
  mixins: [mixin]
};
</script>
