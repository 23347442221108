import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { supportedLocales, defaultLocale } from "@/config"

Vue.use(VueI18n)

export const languages = Object.getOwnPropertyNames(loadLocaleMessages());

export default new VueI18n({
    locale: getBrowserLocale({ countryCodeOnly: true }) || process.env.VUE_APP_I18N_LOCALE || defaultLocale,
    // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLocale,
    fallbackLocale: false,
    //silentFallbackWarn: true,
    silentTranslationWarn: true,
    messages: loadLocaleMessages()
})



function loadLocaleMessages() {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.yml$/i)
    const messages = {}
    locales.keys().forEach(key => {
            // console.log("Key is: " + key)
            const matched = key.match(/([A-Za-z0-9-_]+)\./i)
            if (matched && matched.length > 1) {
                const locale = matched[1]
                messages[locale] = locales(key)
            }
        })
        //console.log("messages are: " + JSON.stringify(messages));
    return messages
}



export function getSupportedLocales() {
    let annotatedLocales = []
    for (const code of Object.keys(supportedLocales)) {
        annotatedLocales.push({
            code,
            name: supportedLocales[code]
        })
    }
    return annotatedLocales
}


export function isLocaleSupported(locale) {
    return Object.keys(supportedLocales).includes(locale)
}

/*
navigator.languages == ["ar-SA", "en-US"]
getBrowserLocale()                          // => "ar-SA"
getBrowserLocale({ countryCodeOnly: true }) // => "ar
*/
export function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false }
    const opt = {...defaultOptions, ...options }
    const navigatorLocale =
        navigator.languages !== undefined ?
        navigator.languages[0] :
        navigator.language
    if (!navigatorLocale) {
        return undefined
    }
    const trimmedLocale = opt.countryCodeOnly ?
        navigatorLocale.trim().split(/-|_/)[0] :
        navigatorLocale.trim()
    return trimmedLocale
}

export function configureLocaleUrl(context) {
    let input_lang = context.$route.query.lang;
    if (input_lang !== undefined) {
        if (isLocaleSupported(input_lang)) {
            context.$i18n.locale = input_lang;
        } else {
            // context.$i18n.locale = defaultLocale;
            context.$router.push({
                    path: window.location.pathname,
                    query: { lang: defaultLocale },
                },
                () => {}
            ); // the second .push value "() => {}" prevents the error message on console
        }
    }
}