<template>
<button class="btn" @click="$emit('click')">
    <slot></slot>
</button>
</template>

<script>
export default {
    name: "UiButton",
    methods: {
        login() {
            console.log("login");
        },
    },
};
</script>

<style lang="scss">
.btn {
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    transition: all .3s ease;
    //border: 1px solid #887373;
    border: none;
    background: #ffffff;
    //width: 100%;
    cursor: pointer;

    &:hover {
        background: #bd4510;
        color: #fff;
    }

    &:active {
        background: #a1390c;
        color: #fff;
    }

    &:focus {
        background: #bd4510;
    }

    &--small {
        padding: 5px 10px;
    }

    &--bordered {
        border: 1px solid #DEDEDE;
    }

    &--text {
        background: none;
        border: none;
        color: white;

        &:hover {
            background: none;
        }
    }
}
</style>
