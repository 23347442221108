<template>
<div id="ui-modal">
    <cookie-law storageName="CNcookies" storageType="cookies" position="top" transitionName="slideFromTop" v-on:accept="accepted()" v-on:close="rejected()">
        <div slot-scope="props">
            <p class="cookie-text">
                {{ $t("c_text") }} <br /> {{ $t("c_text_two") }}
                <a target="_blank" class="privacy-link" :href="
              'https://app.longboat.com/gateway/privacy?locale=' + $i18n.locale
            ">{{ $t("c_privacy") }}</a>
            </p>
            <ui-button class="accept-btn btn--small" @click="props.accept">
                <span v-html="$t('c_accept')"></span>
            </ui-button>
            <br />
            <ui-button class="reject-btn btn--text " @click="props.close">
                <span v-html="$t('c_nocookie')"></span>
            </ui-button>
        </div>
    </cookie-law>
</div>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import UiButtonVue from './UiButton.vue';


export default {
    components: {
        CookieLaw,
        "ui-button": UiButtonVue
    },
    mounted() {

        if (this.$cookies.get('CNcookies') === "false") {
            document.getElementById("ui-modal").style.display = "none";
        }

    },
    methods: {
        accepted() {
            // Get the modal
            var modal = document.getElementById("ui-modal");
            modal.style.display = "none";
            this.$emit('consentAccepted', true)
        },
        rejected() {
            this.$cookies.set('CNcookies', "false", 0)
            // Get the modal
            var modal = document.getElementById("ui-modal");
            modal.style.display = "none";
        },
    },
};
</script>

<style lang="scss" scoped>
/* The Modal (background) */
#ui-modal {
    position: fixed;
    z-index: 999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(75, 75, 75, 0.2);
}

.accept-btn {
    background: #2f802f;
    color: white;

    &:hover {
        background: darken(#2f802f, 10%);
    }

    /*margin-right: 20px;*/
}

.reject-btn {
    color: #1caae7;

    &:hover {
        //color: #2f802f;
        text-decoration: underline;
    }
}

.Cookie--base {
    background: black;
    color: white;
    padding: 20px 16px 10px;
}

.Cookie>* {
    margin: auto;
}

.cookie-text {
    text-align: center;
    margin: 0;
    font-size: 16px;
    margin-bottom: 5px;
}

.privacy-link {
    color: #1caae7;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
</style>
