<template>
<div id="ui-login-wrapper">
    <header class="lang-dropdown-wrapper">
        <lang-dropdown :locales="locales" class="login-lang-dropdown" @localeChange="handleLocaleChangeEvent"></lang-dropdown>
    </header>
    <main id="js-login" class="login">
        <div class="login__logo-wrapper" :class="{ 'login__logo-wrapper--bg': !isDefaultStyle }">
            <img v-if="isDefaultStyle" class="login__logo" :src="require(`@/assets/images/${logoSrc}`)" alt="" />
        </div>
        <div class="login__form-wrapper">
            <div class="login__form">
                <img v-if="!isDefaultStyle" class="login__form-logo" :src="require(`@/assets/images/${logoSrc}`)" alt="" />
<!--                 <h1 class="login__form-heading" v-html="$t('login_welcome_title')"></h1>
 -->                <p class="login__form-text" v-html="$t('login_welcome_text_html')"></p>
                <form class="login-form" v-on:submit.prevent="login">
                    <label :aria-label="$t('login_password')" class="login__password-input-label">
                        <input class="login__password-input" :class="{ 'login__password-input--error': error }" :placeholder="$t('login_password')" type="password" name="password" v-model.trim="password" @input="removeErrorAlert" />
                    </label>
                    <div role="alert" class="d-login-alert-error" v-if="error">
                        <i class="login-error-icon fas fa-exclamation-circle"></i>
                        {{ $t("password_error_message") }}
                    </div>
                    <ui-button type="button" class="login-btn" @click="login()">
                        {{ $t("login_button") }}
                    </ui-button>
                </form>
            </div>
            <copyright-footer class="login-copyright" :locale="$i18n.locale"></copyright-footer>
        </div>
    </main>
    <aside>
        <cookie-modal v-on:consentAccepted="acceptConsent"></cookie-modal>
    </aside>
</div>
</template>

<script>
import CookieModal from "@/components/CookieModal.vue";
import utils from "@/common/utils";
import consent from "@/common/consent";
import auth from "@/plugins/auth";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import UiButtonVue from "@/components/UiButton.vue";
import CopyRightLabel from "@/components/CopyRightLabel.vue";
import {
    isDefaultStyle,
    logoPath,
} from "@/config";
import {
    getSupportedLocales
} from "@/plugins/i18n";

export default {
    components: {
        "lang-dropdown": LocaleSwitcher,
        "ui-button": UiButtonVue,
        "copyright-footer": CopyRightLabel,
        "cookie-modal": CookieModal,
    },
    data() {
        return {
            isDefaultStyle: !isDefaultStyle,
            error: null,
            password: null,
            locales: getSupportedLocales(),
            logoSrc: logoPath,
        };
    },
    mounted() {
        utils.resizeOverlay("js-login");
        document.title = this.$route.meta.pageTitle;
    },
    methods: {
        login() {
            auth.login(this, this.password);
        },
        removeErrorAlert() {
            this.error = false;
        },

        acceptConsent() {
            consent.setStatus(true);
          //  console.log("Login::consent status is: " + consent.status)
            utils.wistiaCookieTracking(true);
        },
         handleLocaleChangeEvent(locale){
            utils.handleLocaleChangeEvent(this,locale);
        }
    },
};
</script>

<style lang="scss">
@import "../assets/styles/login.scss";
</style>
